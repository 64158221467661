import {Calendar, CalendarChangeEvent} from "primereact/calendar";
import {useContext, useEffect, useState} from "react";
import {Nullable} from "primereact/ts-helpers";
import {Dialog} from "primereact/dialog";
import {PickList, PickListChangeEvent} from "primereact/picklist";
import "./TransactionHistory.scss";
import {getJsonFromBackend, postJsonToBackend} from "../../data/network";
import {Hotel, ReturnedError, Role} from "../../data/BackendClasses";
import {MultiSelect, MultiSelectChangeEvent} from "primereact/multiselect";
import {Chips, ChipsAddEvent, ChipsRemoveEvent} from "primereact/chips";
import {useCurrentUser} from "../../data/user";
import {GlobalContext} from "../../data/GlobalContext";
import {formHasErrors} from "../../lib/helpers";
import {Tooltip} from "primereact/tooltip";
import {isReturnedErrors} from "../../components/ErrorModal";

const accountsValues = [
    "Default", "All", "Others", "6157", "Gross earnings", "7584"
]

export default function TransactionHistory() {

    const currentUser = useCurrentUser();

    const [startDate, setStartDate] = useState<string | Date | Date[] | undefined | null>(undefined);
    const [endDate, setEndDate] = useState<Nullable<Date | Date[] | string>>(null);
    const [showHotelsPickList, setShowHotelsPickList] = useState<boolean>(false);
    const [availableHotels, setAvailableHotels] = useState<Hotel[]>([]);
    const [selectedHotels, setSelectedHotels] = useState<Hotel[]>([]);
    const [hotelsChanged, setHotelsChanged] = useState<boolean>(false);
    const [hotelsLoaded, setHotelsLoaded] = useState<boolean>(false);

    const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
    const [emails, setEmails] = useState<string[] | undefined>([]);
    const [dirty, setDirty] = useState<boolean>(false);
    const erori: { [x: string]: string[] } = {accounts: [], hotels: [], startMonth: [], endMonth: [], emails: []};

    const {showToastMessage} = useContext(GlobalContext);

    const baseUrl = process.env.PUBLIC_URL + "";

    useEffect(() => {

        if(currentUser.roles.includes(Role.TRANSACTION_GLOBAL)){
            getJsonFromBackend("/hotels/get-with-profile")
                .then((rsp: Hotel[]) => {
                    setAvailableHotels(rsp);
                    setHotelsLoaded(true);
                })
                .catch(err => {
                    showToastMessage("Error", "Error getting the data about the hotels", "error");
                    console.log(err);
                })
        } else {
            getJsonFromBackend("/hotels/get-my-hotels-with-profile")
                .then((rsp: Hotel[]) => {
                    setAvailableHotels(rsp);
                    setHotelsLoaded(true);
                })
                .catch(err => {
                    showToastMessage("Error", "Error getting the data about the hotels", "error");
                    console.log(err);
                })
        }


    }, [])

    function onChange(event: PickListChangeEvent) {
        setHotelsChanged(true);
        setAvailableHotels(event.source);
        setSelectedHotels(event.target);
        setDirty(true);
    };

    function hideDialog(){
        setShowHotelsPickList(false);
        setHotelsChanged(false);
    }

    function changeStartDate(e: CalendarChangeEvent) {
        // e.value && console.log("---> START ", e.value.toLocaleString('en-US', { month: '2-digit', year: 'numeric' }).replace(/\//g, '-'))
        setStartDate(e.value);
        setDirty(true);
    }

    function changeEndDate(e: CalendarChangeEvent) {
        setEndDate(e.value);
        setDirty(true);
    }

    function multiSelectChanged(e: MultiSelectChangeEvent){
        setSelectedAccounts(e.value);
        setDirty(true);
    }

    function emailRemoved(e: ChipsRemoveEvent){
        setEmails(prevState => {
            if(undefined !== prevState) {
                return prevState.filter((item) => item != e.value);
            }
        })
        setDirty(true);
    }

    function emailAdded(e: ChipsAddEvent) {
        if(isValidEmail(e.value)){
             setEmails(undefined !== emails ? [...emails, e.value] : [e.value])
        } else {
            erori.emails.push("Email is not valid");
            showToastMessage("Warning", "Provided email is not a valid email value", "warn");
        }
        setDirty(true);
    }

    if(selectedAccounts.length == 0){
        erori.accounts.push("You should select at least 1 account")
    }

    if(selectedHotels.length == 0){
        erori.hotels.push("You should select at least 1 hotel")
    }

    if(!currentUser.roles.includes(Role.TRANSACTION_GLOBAL) && (emails == undefined || emails?.length == 0)){
        erori.emails.push("You must provide at least 1 email")
    }

    if(startDate == null) {
        erori.startMonth.push("Start month is mandatory");
    }

    if(endDate == null) {
        erori.endMonth.push("End month is mandatory");
    }

    if(startDate != null && endDate != null && startDate > endDate){
        erori.startMonth.push("Start month can not be bigger than the end month")
        erori.endMonth.push("End month can not be lower than the start month")
    }

    function isValidEmail(email: string) : boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }


    function toLocaleString(inputDate: string | Date | Date[] | undefined | null) : string {
        return inputDate ? inputDate.toLocaleString('en-US', { month: '2-digit', year: 'numeric' }).replace(/\//g, '-') : "";
    }

    async function generateTransactionHistory() {
        // const transactionBody = {accounts: selectedAccounts, hotels: selectedHotels, fromDate: startDate, toDate: endDate, emails: emails};
        const transactionBody = {accounts: selectedAccounts, hotels: selectedHotels, fromDate: toLocaleString(startDate), toDate: toLocaleString(endDate), emails: emails};
        console.log("GENERATE ", transactionBody);
        await postJsonToBackend("/transactions/generate", transactionBody)
            .then(rsp => {
                showToastMessage("Success", "We have launched your Rocket report. It will be delivered to the email(s) within 30 minutes. In the meantime, we suggest having coffee, walking, or calling a loved one :)", "success");

                setTimeout(function() {
                    window.location.reload();
                }, 5000);
            }).catch(err => {
                if(isReturnedErrors(err)){
                    showToastMessage("Error", err.errors[0].message, "error");
                } else {
                    showToastMessage("Error", "Error sending the data", "error");
                }

                console.log(err);
            })
    }

    const itemTemplate = (item: Hotel) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold">{item.name}</span>
                </div>
            </div>
        );
    };

    function filterAccountsValuesByRole(roles: Role[]) : string[] {
        if(currentUser.roles.includes(Role.TRANSACTION_GLOBAL))
            return accountsValues;
        if(currentUser.roles.includes(Role.TRANSACTION_DEFAULT))
            return ["Default", "Gross earnings"]
        return []
    }

    return <div id="transaction-history-page" className="flex flex-col items-center justify-center w-full gap-2 h-full">

        <div className="container mb-20 w-full">
            <img src={baseUrl + "/name_logo.png"} className="w-1/3 m-auto"/>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 p-6 bg-white/70 backdrop-blur rounded-xl flex flex-col">
            <div className="w-3/4 gap-2 flex content-center justify-center m-auto">
                <div className="w-3/4 flex flex-col justify-center">
                    <label htmlFor="account-select" className="font-medium text-blue-500">Bank Accounts</label>

                    <div className="flex flex-row">
                        <MultiSelect value={selectedAccounts} onChange={multiSelectChanged} options={filterAccountsValuesByRole(currentUser.roles)}  display="chip"
                                     placeholder="Select Accounts" maxSelectedLabels={3} className={`w-full md:w-20rem ${erori.accounts.length > 0 && dirty ? "p-invalid" : ""}`} />
                        <div className="flex flex-col justify-center ml-2">
                            <i className={`fa-regular fa-circle-question tooltip-accounts-target cursor-pointer ${erori.accounts.length > 0 && dirty ? "text-red-500" : "text-indigo-500"}`}/>
                            <Tooltip target=".tooltip-accounts-target" >
                                <div className="flex flex-col w-[400px]">
                                    <span>Default - Property bank account attached to Airbnb. This is where your payouts are deposited.</span>
                                    <br/>
                                    <span>Gross Earnings - If your hotel is paid taxes by Airbnb, you can download this report as a copy of what was paid on your behalf.</span>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <div className="w-1/4 flex flex-col content-center justify-center">
                    {hotelsLoaded && <button
                        className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 py-2 mt-[24px]"
                        onClick={() => setShowHotelsPickList(true)}
                    >
                        Hotels
                        <i className="fa-solid fa-hotel ml-2"/>
                        {selectedHotels.length > 0 && <span className="text-xs ml-2 rounded-full bg-white p-1">{selectedHotels.length}</span>}
                    </button>}
                </div>
            </div>

            {(erori.accounts.length > 0 || erori.hotels.length > 0) && dirty && <div className="w-3/4 gap-2 flex content-center justify-center m-auto">
                <div className="w-3/4 flex flex-col justify-center">
                    {erori.accounts.length > 0 && <span className="text-red-500 font-sm">{erori.accounts[erori.accounts.length-1]}</span>}
                </div>
                <div className="w-1/4 flex flex-col justify-center">
                    {erori.hotels.length > 0 && <span className="text-red-500 font-sm">{erori.hotels[erori.hotels.length-1]}</span>}
                </div>
            </div>}

            <div className="flex w-3/4 gap-2 mt-4 m-auto">
                <div className="w-1/2 flex flex-col">
                    <label className="font-medium text-blue-500" htmlFor="start-date">Start Month</label>
                    <Calendar
                        id="calendar-24h"
                        value={startDate}
                        onChange={(e) => changeStartDate(e)}
                        showIcon
                        showButtonBar
                        view="month"
                        dateFormat="mm-yy"
                        maxDate={currentUser.roles.includes(Role.ADMIN) ? new Date() : new Date(1730411940000)}
                        className={`${erori.startMonth.length > 0 && dirty ? "p-invalid" : ""}`}
                    />
                    {erori.startMonth.length > 0 && dirty && <span className="text-red-500 font-sm">{erori.startMonth[erori.startMonth.length-1]}</span>}
                </div>

                <div className="w-1/2 flex flex-col">
                    <label className="font-medium text-blue-500" htmlFor="start-date">End Month</label>
                    <Calendar
                        id="calendar-24h"
                        value={endDate}
                        onChange={(e) => changeEndDate(e)}
                        showIcon
                        showButtonBar
                        view="month"
                        dateFormat="mm-yy"
                        maxDate={currentUser.roles.includes(Role.ADMIN) ? new Date() : new Date(1730411940000)}
                        className={`${erori.endMonth.length > 0 && dirty ? "p-invalid" : ""}`}
                    />
                    {erori.endMonth.length > 0 && dirty && <span className="text-red-500 font-sm">{erori.endMonth[erori.endMonth.length-1]}</span>}
                </div>
            </div>

            <div className="w-full flex flex-col mt-4">
                <label className="font-medium text-blue-500 w-3/4 m-auto" htmlFor="">Emails (after each email hit enter)</label>
                <div className="w-3/4 m-auto flex flex-row">
                    <Chips className={`w-full ${erori.emails.length > 0 && dirty ? "p-invalid" : ""}`}  name="emails" value={emails} onRemove={emailRemoved} onAdd={emailAdded}/>
                    <div className="flex flex-col justify-center ml-2">
                        <i className={`fa-regular fa-circle-question tooltip-emails-target cursor-pointer ${erori.emails.length > 0 && dirty ? "text-red-500" : "text-indigo-500"}`}/>
                        <Tooltip target=".tooltip-emails-target" >
                            <span>After each email hit enter</span>
                        </Tooltip>
                    </div>
                </div>
                {erori.emails.length > 0 && dirty && <span className="text-red-500 font-sm m-auto">{erori.emails[erori.emails.length-1]}</span>}
            </div>

            <div className="w-full text-center">
                {/*{isFormValid() && dirty && <button*/}
                {!formHasErrors(erori) && dirty && <button
                    className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 p-2 mt-5"
                    onClick={generateTransactionHistory}
                >
                    Submit
                </button>}
            </div>

            <Dialog
                header="Choose hotels"
                visible={showHotelsPickList}
                style={{ width: '50vw' }}
                onHide={hideDialog}
            >
                <div className="flex flex-col w-full">
                    <PickList
                        source={availableHotels}
                        target={selectedHotels}
                        onChange={onChange}
                        itemTemplate={itemTemplate}
                        filter
                        filterBy="name,address"
                        breakpoint="1400px"
                        sourceHeader="Available"
                        targetHeader="Selected"
                        sourceStyle={{ height: '24rem' }}
                        targetStyle={{ height: '24rem' }}
                        sourceFilterPlaceholder="Search by name or address"
                        targetFilterPlaceholder="Search by name or address"
                        showSourceControls={false}
                        showTargetControls={false}
                    />
                    <div className="w-full mt-2 flex justify-center">
                        {hotelsChanged && <button
                            onClick={hideDialog}
                            className="border border-transparent font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 px-2 py-1"
                        >
                            Done
                        </button>}
                    </div>
                </div>
            </Dialog>
        </div>

        <div className="mt-10">Need help? Contact us at <a href="mailto:admin@rocketstay.com" className="text-blue-500">admin@rocketstay.com</a></div>

    </div>



}